<template>
  <div>
    <v-navigation-drawer
      color="primary"
      class="app-menu"
      v-model="$store.state.menu"
      app
      :right="rtl"
      width="250"
      floating
    >
      <v-list nav dense class="pt-10 list d-done">
        <v-list-item class="white--text" to="/dashboard" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">home</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">الرئيسية</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/send_balance" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">payments</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> ارسال رصيد </v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/certificate" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">redeem</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white">
            اضافة شهادة جديدة
            <v-icon color="success" class="ms-2"
              >add_circle</v-icon
            ></v-list-item-title
          >
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/Transactions" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">sync_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> المعاملات</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/profit" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">account_balance_wallet</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> ارباح الشهادات</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/requests" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">sync</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> محفظتي</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item class="white--text" to="/settings" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">settings</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> الأعدادت</v-list-item-title>
        </v-list-item>
        <!--/ list item -->

        <v-list-item @click="logout()" class="white--text" link>
          <v-list-item-icon class="me-4" color="white">
            <v-icon class="white--text">logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title color="white"> تسجيل الخروج</v-list-item-title>
        </v-list-item>
        <!--/ list item -->
      </v-list>

      <!--/ company -->
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .app-menu {
    .v-navigation-drawer__content {
      .list {
        padding-bottom: 100px !important;
      }
      &::-webkit-scrollbar {
        width: 0;
        scrollbar-width: none;
      }
    }
  }
}
</style>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {};
  },
  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      vm.$router.push({ name: "login" });
    },
  },
};
</script>
